import React, {useMemo} from "react";
import {Box, Stack} from "@mui/material";
import General from "../components/modules/home/connect/profile/data";
import UserSettings from "../components/modules/home/connect/profile/Settings/UserSettings";
import Integrations from "../components/modules/home/control-center/integrations";
import CustomInstructions from "../components/modules/ai-studio/settings/custom-instructions";
import Security from "../components/modules/home/connect/profile/Security";
import UserToken from "../components/modules/home/user-token";
import DataRestore from "../components/modules/home/control-center/data-restore";
import EmailAdminPanel from "../components/modules/home/email/email-admin-panel";
import TeaserCard from "../components/elements/teaser-card";
import ThemeBuilder from "../components/modules/home/theme-builder";
import DeliveryOptions from "../components/modules/resources/shipping";
import {APP_SETTING} from "../../setup";
import UsersAndGroups from "../components/modules/home/users-and-groups";

function useGroonSettings(){

    const customSettings = APP_SETTING?.settings || [];

    const accountSettings = {
        label: 'mgt.settings.account_setup',
        icon: 'User03',
        settings: [
            {
                label: 'mgt.settings.profile_settings',
                subline: 'mgt.settings.profile_general_subline',
                value: 'profile-general',
                icon: 'User01',
                component:  (
                    <Box>
                        <General/>
                    </Box>
                )
            },
            {
                label: 'common.settings',
                subline: 'mgt.settings.profile_settings_subline',
                value: 'profile-settings',
                icon: 'Settings01',
                component:  (
                    <Box>
                        <UserSettings />
                    </Box>
                )
            },
            {
                // TODO: we will need to assign rights to integrations (thus, connectors)
                label: 'layout.integrations',
                subline: 'mgt.settings.ai_integrations_subline',
                value: 'ai-integrations',
                icon: 'Link04',
                rights: ['integrations'],
                component: <Integrations />
            },
            {
                label: 'intelligence.ai_chat',
                subline: 'mgt.settings.custom_instructions_subline',
                value: 'custom-instructions',
                rights: ["intelligence"],
                icon: 'AnnotationInfo',
                component:  (
                    <Box>
                        <CustomInstructions />
                    </Box>
                )
            },
            {
                label: 'common.security',
                subline: 'mgt.settings.profile_security_subline',
                value: 'profile-security',
                icon: 'Fingerprint02',
                component:  (
                    <Box>
                        <Security/>
                    </Box>
                )
            },
            {
                label: 'common.token',
                subline: 'mgt.settings.profile_token_subline',
                value: 'profile-token',
                rights: ["api_tokens"],
                icon: 'Key01',
                component:  (
                    <Box>
                        <UserToken />
                    </Box>
                )
            },
        ]
    }

    const platformSettings = {
        label: 'common.platform',
        icon: 'Home01',
        settings: [
            {
                label: 'mgt.settings.users_and_groups',
                subline: 'mgt.settings.users_and_groups_subline',
                value: 'users-and-groups',
                rights: ["manage_users"],
                icon: 'Users01',
                component: <UsersAndGroups />
            },
            {
                label: 'common.general',
                subline: 'mgt.settings.core_general_subline',
                value: 'general',
                rights: ["general_settings"],
                icon: 'Settings03',
                items: [
                    {
                        name: 'company_name',
                        label: "mgt.settings.company_name",
                        description: "mgt.settings.company_name_info",
                        type: 'text'
                    },
                    {
                        name: 'company_description',
                        label: "mgt.settings.company_description",
                        description: "mgt.settings.company_description_info",
                        multiline: true,
                        maxRows: 8,
                        type: 'text'
                    },
                    {
                        name: 'cloud_front_domain',
                        label: "mgt.settings.cloud_front_domain",
                        description: "mgt.settings.cloud_front_domain_info",
                        isDisabled: (values) => (values?.cloud_front_active || false) === false,
                        type: 'text'
                    },
                    // {
                    //     name: 'past_jobs_length',
                    //     label: "mgt.settings.saved_jobs",
                    //     description: "mgt.settings.saved_jobs_info",
                    //     type: 'number'
                    // },
                    {
                        name: 'cache_duration',
                        label: "mgt.settings.cache_duration",
                        description: "mgt.settings.cache_duration_info",
                        type: 'number'
                    },
                    {
                        name: 'cached_search_amount',
                        label: "mgt.settings.cached_search_amount",
                        description: "mgt.settings.cached_search_amount_info",
                        type: 'number'
                    },
                    {
                        name: 'sync_error_reports',
                        label: "mgt.settings.sync_errors",
                        description: "mgt.settings.sync_errors_info",
                        type: 'switch',
                    },
                    {
                        name: 'auto_approve_requests',
                        label: "mgt.settings.auto_approve_tickets",
                        description: "mgt.settings.auto_approve_tickets_info",
                        type: 'switch',
                    },
                    {
                        name: 'cloud_front_active',
                        label: "mgt.settings.cloud_front_active",
                        description: "mgt.settings.cloud_front_active_info",
                        type: 'switch'
                    },

                    // DEBUG parameters
                    // {
                    //     name: 'test_kpi_amount',
                    //     label: "Test KPI Daten",
                    //     description: "Menge an Test KPI-Daten, die generiert werden sollen",
                    //     type: 'number'
                    // },
                    // {
                    //     name: 'max_graph_render_steps',
                    //     label: "Maximale Berechnungsschritte (Graphen)",
                    //     description: "Die maximalen Iterationen, um das Layout eines Graphen zu berechen",
                    //     type: 'number'
                    // },
                ],

            },
            {
                label: 'mgt.settings.security',
                subline: 'mgt.settings.security_subline',
                value: 'security',
                rights: ["security_settings"],
                icon: 'Lock02',
                items: [
                    {
                        name: 'password_lifetime_days',
                        label: "mgt.settings.pw_lifetime",
                        description: "mgt.settings.pw_lifetime_info",
                        type: 'number'
                    },
                    {
                        name: 'token_lifespan',
                        label: "mgt.settings.token_lifespan",
                        description: "mgt.settings.token_lifespan_info",
                        type: 'number'
                    },
                    {
                        name: 'potential_ddos_threshold',
                        label: "mgt.settings.ddos_threshold",
                        default: 10000,
                        description: "mgt.settings.ddos_threshold_info",
                        type: 'number'
                    },
                    {
                        name: 'block_device_threshold',
                        label: "mgt.settings.block_device_threshold",
                        default: 100,
                        description: "mgt.settings.block_device_threshold_info",
                        type: 'number'
                    },
                    {
                        name: 'block_user_threshold',
                        label: "mgt.settings.block_user_threshold",
                        default: 25,
                        description: "mgt.settings.block_user_threshold_info",
                        type: 'number'
                    },
                    {
                        name: 'token_rotation_active',
                        label: "mgt.settings.token_rotation",
                        description: "mgt.settings.token_rotation_info",
                        type: 'switch',
                    },
                ]

            },
            {
                label: 'mgt.settings.email',
                subline: 'mgt.settings.email_subline',
                value: 'emails',
                rights: ["manage_emails"],
                icon: 'Mail02',
                componentBelow: (
                    <Stack spacing={2}>
                        <EmailAdminPanel />
                        <TeaserCard
                            height={600}
                            title="CRM Email-Templates"
                            body="Hier werden bald die Email-Templates vom CRM System verwaltet werden können"
                        />
                    </Stack>
                ),
                items: [
                    {
                        name: 'email_sender',
                        label: "mgt.settings.email_sender",
                        description: "mgt.settings.email_sender_info",
                        type: 'text'
                    },
                    {
                        name: 'email_footer',
                        label: "mgt.settings.email_footer",
                        description: "mgt.settings.email_footer_info",
                        type: 'text'
                    },
                    {
                        name: 'update_email_subject',
                        label: "mgt.settings.update_email_subject",
                        description: "mgt.settings.update_email_subject_info",
                        type: 'text'
                    },
                    {
                        name: 'contact_email',
                        label: "mgt.settings.contact_email",
                        description: "mgt.settings.contact_email_info",
                        type: 'text'
                    },

                    {
                        name: 'standard_email_account',
                        label: "mgt.settings.email_account",
                        description: "mgt.settings.email_account_info",
                        type: 'integration',
                        integration: 'Email Service',
                    },
                ]
            },
            {
                label: 'mgt.settings.design',
                subline: 'mgt.settings.design_subline',
                value: 'design',
                rights: ["theme_management"],
                icon: 'LayoutAlt01',
                items: [
                    {
                        name: 'theme',
                        label: "common.theme",
                        description: "mgt.settings.theme_info",
                        type: 'theme'
                    },
                ],
                componentBelow: <Box><ThemeBuilder /></Box>
            },
            // {
            //     label: 'mgt.settings.template',
            //     subline: 'mgt.settings.template_subline',
            //     value: 'crm-fields',
            //     rights: ["template_settings"],
            //     icon: 'TextInput',
            //     component: (
            //         <TeaserCard
            //             height={400}
            //             title="Zusätzliche Felder"
            //             body="Hier können CRM Kontakten zusätzliche Datenfelder gegeben werden, um das CRM System noch individueller zu machen"
            //         />
            //     )
            // },
        ]
    }

    const modulesSettings = {
        label: 'common.modules',
        icon: 'Data',
        settings: [
            {
                label: 'intelligence.title',
                subline: 'mgt.settings.ai_general_subline',
                value: 'ai-general',
                rights: ["intelligence_configuration"],
                icon: 'Star06',
                items: [
                    {
                        name: 'use_ai',
                        label: "mgt.settings.use_ai",
                        description: "mgt.settings.use_ai_info",
                        type: 'switch',
                    },
                    // {
                    //     name: 'launchpad_update_period',
                    //     label: 'mgt.settings.launchpad_update_period',
                    //     description: "mgt.settings.launchpad_update_period_info",
                    //     type: 'number',
                    //     isDisabled: (values) => (values?.use_ai || false) === false
                    // },
                    // {
                    //     name: 'welcome_line_prompt',
                    //     label: "mgt.settings.welcome_message",
                    //     description: "mgt.settings.welcome_message_info",
                    //     multiline: true,
                    //     type: 'text',
                    //     isDisabled: (values) => (values?.use_ai || false) === false,
                    // },
                    {
                        name: 'smart_search_results',
                        label: "mgt.settings.smart_search_results",
                        description: "mgt.settings.smart_search_results_info",
                        type: 'number',
                        default: 10,
                        isDisabled: (values) => (values?.use_ai || false) === false,
                        min: 1,
                        max: 100
                    },
                    {
                        name: 'similarity_search_threshold_object',
                        label: "mgt.settings.similarity_search_threshold_object",
                        description: "mgt.settings.similarity_search_threshold_object_info",
                        type: 'number',
                        isDisabled: (values) => (values?.use_ai || false) === false,
                        decimalPlaces: 2,
                        default: 0.3,
                        min: 0,
                        max: 1
                    },
                    {
                        name: 'similarity_search_threshold_image',
                        label: "mgt.settings.similarity_search_threshold_image",
                        description: "mgt.settings.similarity_search_threshold_image_info",
                        type: 'number',
                        isDisabled: (values) => (values?.use_ai || false) === false,
                        decimalPlaces: 2,
                        default: 0.15,
                        min: 0,
                        max: 1
                    },
                    {
                        name: 'similarity_search_threshold_text_image',
                        label: "mgt.settings.similarity_search_threshold_text_image",
                        description: "mgt.settings.similarity_search_threshold_text_image_info",
                        type: 'number',
                        isDisabled: (values) => (values?.use_ai || false) === false,
                        decimalPlaces: 2,
                        default: 0.15,
                        min: 0,
                        max: 1
                    },
                    {
                        name: 'similarity_search_threshold_text',
                        label: "mgt.settings.similarity_search_threshold_text",
                        description: "mgt.settings.similarity_search_threshold_text_info",
                        type: 'number',
                        isDisabled: (values) => (values?.use_ai || false) === false,
                        decimalPlaces: 2,
                        default: 0.15,
                        min: 0,
                        max: 1
                    },
                    // {
                    //     name: 'similarity_search_threshold_video',
                    //     label: "mgt.settings.similarity_search_threshold_video",
                    //     description: "mgt.settings.similarity_search_threshold_video_info",
                    //     type: 'number',
                    //     isDisabled: (values) => (values?.use_ai || false) === false,
                    //     decimalPlaces: 2,
                    //     default: 0.15,
                    //     min: 0,
                    //     max: 1
                    // },
                    {
                        name: 'groon_assistant',
                        label: "mgt.settings.assistant_name",
                        description: "mgt.settings.assistant_name_info",
                        type: 'smart-selector',
                        isDisabled: (values) => (values?.use_ai || false) === false,
                        endpoint: 'core/assistants',
                        query: {public: true, is_model_wrapper: false}
                    },
                    {
                        name: 'assistant_chat_history',
                        label: 'mgt.settings.assistant_chat_history',
                        description: "mgt.settings.assistant_chat_history_info",
                        type: 'number',
                        isDisabled: (values) => (values?.use_ai || false) === false,
                    },
                    {
                        name: 'autonomous_activity_message_threshold',
                        label: 'mgt.settings.autonomous_activity_message_threshold',
                        description: "mgt.settings.autonomous_activity_message_threshold_info",
                        type: 'number',
                        isDisabled: (values) => (values?.use_ai || false) === false,
                    },
                    {
                        name: 'maximum_action_steps',
                        label: 'mgt.settings.maximum_action_steps',
                        description: "mgt.settings.maximum_action_steps_info",
                        type: 'number',
                        isDisabled: (values) => (values?.use_ai || false) === false,
                    },
                    {
                        name: 'max_memories',
                        label: 'mgt.settings.max_memories',
                        description: "mgt.settings.max_memories_info",
                        type: 'number',
                        isDisabled: (values) => (values?.use_ai || false) === false,
                    },
                    {
                        name: 'allow_parallel_model_use',
                        label: "mgt.settings.allow_parallel_model_use",
                        description: "mgt.settings.allow_parallel_model_use_info",
                        type: 'switch',
                        isDisabled: (values) => (values?.use_ai || false) === false
                    },
                    {
                        name: 'ai_backend',
                        label: "mgt.settings.ai_backend",
                        description: "mgt.settings.ai_backend_info",
                        type: 'smart-selector',
                        isDisabled: (values) => (values?.use_ai || false) === false,
                        endpoint: 'ai/ai_models',
                        query: {input_modalities: 'text', output_modalities: 'text'}
                    },
                    {
                        name: 'annotation_model',
                        label: "mgt.settings.annotation_model",
                        description: "mgt.settings.annotation_model_info",
                        type: 'smart-selector',
                        isDisabled: (values) => (values?.use_ai || false) === false,
                        endpoint: 'ai/ai_models',
                        query: {input_modalities: 'image', output_modalities: 'text'}
                    },
                    {
                        name: 'text_embedding_model',
                        label: "mgt.settings.text_embedding_model",
                        description: "mgt.settings.text_embedding_model_info",
                        type: 'smart-selector',
                        isDisabled: (values) => (values?.use_ai || false) === false,
                        endpoint: 'ai/ai_models',
                        query: {input_modalities: 'text', output_modalities: 'vector'}
                    },
                    {
                        name: 'text_image_embedding_model',
                        label: "mgt.settings.text_image_embedding_model",
                        description: "mgt.settings.text_image_embedding_model_info",
                        type: 'smart-selector',
                        isDisabled: (values) => (values?.use_ai || false) === false,
                        endpoint: 'ai/ai_models',
                        query: {input_modalities: ['text', 'image'], output_modalities: 'vector'}
                    },
                    {
                        name: 'image_embedding_model',
                        label: "mgt.settings.image_embedding_model",
                        description: "mgt.settings.image_embedding_model_info",
                        type: 'smart-selector',
                        isDisabled: (values) => (values?.use_ai || false) === false,
                        endpoint: 'ai/ai_models',
                        query: {input_modalities: 'image', output_modalities: 'vector'}
                    },
                    // Skip for now
                    // {
                    //     name: 'video_embedding_model',
                    //     label: "mgt.settings.video_embedding_model",
                    //     description: "mgt.settings.video_embedding_model_info",
                    //     type: 'smart-selector',
                    //     isDisabled: (values) => (values?.use_ai || false) === false,
                    //     endpoint: 'ai/ai_models',
                    //     query: {input_modalities: 'video', output_modalities: 'vector'}
                    // },
                ],
            },
            {
                label: 'resources.title',
                subline: 'mgt.settings.resources_general_subline',
                value: 'general1',
                rights: ["erp_general_configuration"],
                icon: 'Atom02',
                component: (
                    <Stack spacing={2}>
                        <DeliveryOptions/>
                        <TeaserCard
                            height={400}
                            title="Nummernkreise"
                            body="Hier können bald die Nummernkreise für alle Belegarten konfiguriert werden."
                        />
                        <TeaserCard
                            height={400}
                            title="Geschäftsjahr"
                            body="Hier sind bald alle Einstellungen rund um die Geschäftsjahre zu finden."
                        />
                    </Stack>
                ),
                items: [
                    {
                        name: 'checkout_active',
                        label: "resources.settings.general.checkout_active_label",
                        description: "resources.settings.general.checkout_active_info",
                        type: 'switch',
                    },
                    {
                        name: 'max_transactions_per_second',
                        label: "resources.settings.general.max_transactions_per_second",
                        description: "resources.settings.general.max_transactions_per_second_info",
                        type: 'number',
                        min: 0
                    },
                    {
                        name: 'days_return_deadline',
                        label: "resources.settings.deadlines.return_label",
                        description: "resources.settings.deadlines.return_info",
                        type: 'number',
                        suffix: 'common.days'
                    },
                    {
                        name: 'days_service_return_deadline',
                        label: "resources.settings.deadlines.service_return_label",
                        description: "resources.settings.deadlines.service_return_info",
                        type: 'number',
                        suffix: 'common.days'
                    },
                    {
                        name: 'days_payment_overdue',
                        label: "resources.settings.deadlines.payment_deadline_label",
                        description: "resources.settings.deadlines.payment_deadline_info",
                        type: 'number',
                        suffix: 'common.days'
                    },
                    {
                        name: 'days_payment_overdue_kill',
                        label: "resources.settings.deadlines.cancel_deadline_label",
                        description: "resources.settings.deadlines.cancel_deadline_info",
                        type: 'number',
                        suffix: 'common.days'
                    },
                    {
                        name: 'days_processing_overdue',
                        label: "resources.settings.deadlines.processing_due_warning_label",
                        description: "resources.settings.deadlines.processing_due_warning_info",
                        type: 'number',
                        suffix: 'common.days'
                    },
                    {
                        name: 'days_return_overdue',
                        label: "resources.settings.deadlines.return_overdue_label",
                        description: "resources.settings.deadlines.return_overdue_info",
                        type: 'number',
                        suffix: 'common.days'
                    },
                ]
            },
            {
                label: 'layout.marketing.title',
                subline: 'mgt.settings.website_subline',
                value: 'website',
                rights: ["marketing_configuration"],
                icon: 'Globe04',
                items: [
                    {
                        label: 'marketing.platforms.website.items.active.label',
                        name: 'cms_settings.cmsSystemActive',
                        description: 'marketing.platforms.website.items.active.description',
                        type: 'switch',
                        defaultValue: false
                    },
                    {
                        label: 'marketing.platforms.website.items.maintainance.label',
                        name: 'cms_settings.maintenanceMode',
                        description: 'marketing.platforms.website.items.maintainance.description',
                        type: 'checkbox',
                        defaultValue: false
                    },
                    {
                        label: "marketing.settings.on_analytics",
                        name: 'cms_settings.omniaAnalytics',
                        description: 'marketing.settings.on_analytics_info',
                        type: 'checkbox',
                        defaultValue: false
                    },
                    {
                        label: "marketing.platforms.website.items.meta_pixel.label",
                        name: 'cms_settings.metaPixel',
                        description: "marketing.platforms.website.items.meta_pixel.description",
                        type: 'text',
                        defaultValue: null
                    },
                    {
                        label: "marketing.platforms.website.items.google_analytics.label",
                        name: 'cms_settings.googleAnalyticsKey',
                        description: "marketing.platforms.website.items.google_analytics.description",
                        type: 'text',
                        defaultValue: null
                    },
                    {
                        label: "marketing.platforms.website.items.blog_default_author.label",
                        name: 'cms_settings.blogDefaultAuthor',
                        description: "marketing.platforms.website.items.blog_default_author.description",
                        type: 'text',
                        defaultValue: 'AIOS'
                    },
                    {
                        label: "marketing.platforms.website.items.blog_read_seconds.label",
                        name: 'cms_settings.blogReadSeconds',
                        description: "marketing.platforms.website.items.blog_read_seconds.description",
                        type: 'number',
                        defaultValue: 10
                    },
                    {
                        label: 'marketing.platforms.website.items.competitor_is_active_threshold.label',
                        name: 'cms_settings.competitorIsActive',
                        description: "marketing.platforms.website.items.competitor_is_active_threshold.description",
                        type: 'number',
                        defaultValue: 7
                    },
                    {
                        label: 'marketing.settings.visit_duration_threshold',
                        name: 'cms_settings.visit_duration_threshold',
                        description: 'marketing.settings.visit_duration_threshold_info',
                        type: 'number',
                        decimalPlaces: 0,
                        defaultValue: 5
                    },
                ],
            },
        ]
    }

    return useMemo(() => {

        // Inject custom settings in moduleSettings.settings
        modulesSettings.settings = [
            ...modulesSettings.settings,
            ...customSettings
        ]

        // Create the final settings
        return [
            accountSettings,
            platformSettings,
            modulesSettings,
        ]

    }, [customSettings]);

}

export default useGroonSettings;