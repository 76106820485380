import React, {useEffect, useState} from 'react';
import {useFormik} from "formik";
import {
    Box,
    Dialog,
    DialogContent,
    Button,
    TextField,
    Typography,
    Grid,
    MenuItem,
    DialogActions, DialogTitle, Stack,
} from '@mui/material';
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {addUserGroup, updateUserGroup} from "src/omnia/store/actions/user-groups-actions";
import {useNotifications} from "src/omnia/hooks/use-notifications";
import useApi from "src/omnia/hooks/use-api";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import SmartSelector from "../../../../elements/smart-selector";
import SaveButton from "../../../../elements/save-button";
import {useIsMobile} from "../../../../../hooks/use-is-mobile";

function GroupModal({open, group, handleClose}) {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [data, setData] = useState({users: []});
    const [create, setCreate] = useState(false);
    const users = useSelector(state => state.users.users);
    const {  notifySuccess } = useNotifications();
    const { isMobile } = useIsMobile();
    const { put, get } = useApi();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: data,
        validationSchema: Yup.object().shape({
            name: Yup.string().required(t('form.is_required', {field: t('attributes.name')})),
            type: Yup.string().required(t('form.is_required', {field: t('attributes.type')})),
        }),
        onSubmit: (values, {resetForm, setSubmitting}) => {
            setSubmitting(true);
            put('core/user-groups', values).then(newData => {
                if (create) {
                    dispatch(addUserGroup(newData));
                } else {
                    get('core/user-groups/' + newData.id).then(group => {
                        dispatch(updateUserGroup(group));
                    });
                }
                notifySuccess(t);
                handleClose();
                resetForm();
            }).finally(() => {
                setSubmitting(false);
            })
        }
    });

    const parentTypes = {
        team: "group",
        group: "area",
        area: "org",
        org: "complex"
    }

    useEffect(() => {
        if(group){
            setData(group);
            setCreate(false);
        } else {
            setCreate(true);
            setData({
                name: '',
                type: '',
                inherits: '',
                users: []
            })
        }
    }, [group, users]);

    if(!data)
        return null;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="sm"
            fullScreen={isMobile}
        >
            <DialogTitle>
                <Typography variant="h4">
                    {group ? t('dialogs.edit_group.title') : t('dialogs.add_group.title')}
                </Typography>
                <Typography variant="subtitle1" sx={{mt: 2}}>
                    {group ? t('dialogs.edit_group.description') : t('dialogs.add_group.description')}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    <Box mt={2}>
                        <TextField
                            error={Boolean(formik.touched.name && formik.errors.name)}
                            fullWidth
                            helperText={formik.touched.name && formik.errors.name}
                            label={t('attributes.name')}
                            name="name"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.name}
                        />
                    </Box>
                    <Box mt={2}>
                        <TextField
                            select
                            error={Boolean(formik.touched.type && formik.errors.type)}
                            fullWidth
                            helperText={formik.touched.type && formik.errors.type}
                            label={t('attributes.type')}
                            name="type"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.type}
                        >
                            <MenuItem value="complex">{t('core.group_complex')}</MenuItem>
                            <MenuItem value="org">{t('core.group_org')}</MenuItem>
                            <MenuItem value="area">{t('core.group_area')}</MenuItem>
                            <MenuItem value="group">{t('core.group_group')}</MenuItem>
                            <MenuItem value="team">{t('core.group_team')}</MenuItem>
                        </TextField>
                    </Box>
                    <Box mt={2}>
                        <Grid container spacing={2}>
                            {formik.values.type !== 'complex' && (
                                <Grid item xs={12} sm={6}>
                                    <SmartSelector
                                        endpoint="core/user-groups"
                                        query={{type: parentTypes[formik.values.type]}}
                                        label={t('attributes.parent')}
                                        name="inherits"
                                        multiple={false}
                                        handleChange={formik.handleChange}
                                        values={formik.values.inherits}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12} sm={formik.values.type === 'complex' ? 12 : 6}>
                                <SmartSelector
                                    label={t('core.members')}
                                    multiple
                                    endpoint='core/users'
                                    name="users"
                                    handleChange={formik.handleChange}
                                    values={formik.values.users}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
                    <Button
                        disabled={formik.isSubmitting}
                        onClick={handleClose}
                        type="button"
                        variant="outlined"
                    >
                        {t("common.cancel")}
                    </Button>
                    <SaveButton
                        formik={formik}

                        // loading={formik.isSubmitting}
                        // onClick={formik.handleSubmit}
                        // label={create ? t("common.add") : t("common.save")}
                        // noIcon
                    />
                </Stack>
            </DialogActions>
        </Dialog>
    );
}

GroupModal.propTypes = {
    open: PropTypes.bool,
    group: PropTypes.object,
    handleClose: PropTypes.func
}

export default GroupModal;