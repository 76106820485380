import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {
    Box,
    Breadcrumbs,
    Button,
    Container,
    Divider,
    Fade,
    Link,
    Menu,
    MenuItem,
    Stack,
    SvgIcon,
    Tooltip,
    Typography,
} from "@mui/material";
import {useSettings} from "../../../hooks/use-settings";
import Page from "../../general/page";
import {Link as RouterLink} from "react-router-dom";
import Edit02Icon from "@untitled-ui/icons-react/build/esm/Edit05";
import ChevronDownIcon from "@untitled-ui/icons-react/build/esm/ChevronDown";
import {useTranslation} from "react-i18next";
import {useSecurityCheck} from "../../../hooks/use-security-check";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SaveButton from "../save-button";
import {APP_SETTING} from "../../../../setup";
import BlurredBox from "../blurred-box";
import {useAppMode} from "../../../hooks/use-app-mode";
import OnIcon from "../icon";
import {useIsMobile} from "../../../hooks/use-is-mobile";
import DelayedCircularProgress from "../delayed-circular-progress";
import ShareButton from "../sharing/share-button";
import RemoveSharableObject from "../sharing/remove-sharable-object";
import {useTheme} from "@mui/system";

function DetailViewLayout(props) {

    const {
        children,

        // Rights
        viewRights = [],
        removeRight = [],
        editRight = [],
        actionRights = [],

        // Layout
        headerMaxWidth = null,
        headerFullWidth = false,
        contentMaxWidth = null,
        contentFullWidth = false,
        headerSticky = false,
        fitScreenHeight = false,
        pb = 4,
        pt = 4,

        // Interface
        title = null,
        subtitle = '',
        breadcrumbs = [],
        avatar = null,
        isLoading = false,

        // Editing
        isEditing = false,
        onEdit = null,
        onCancel = null,
        onSave = null,
        isSubmitting = false,

        // Editing 2.0
        formik = null,
        autoSave = false,

        // Actions
        actions = [],
        actionButton,
        removeLabel = null,
        removeTooltip = null,
        onRemove = null,
        shareableEntity = null,
        shareableEndpoint = null,
    } = props;

    const settings = useSettings();
    const {t} = useTranslation();
    const {hasRights} = useSecurityCheck();
    const {isFlying, isMobile, navBarMode} = useIsMobile();
    const [showBreadcrumbs, setShowBreadcrumbs] = useState(false);
    const [showTitle, setShowTitle] = useState(false);
    const [contentAvailable, setActive] = useState(false);
    const [showButtonLeft, setShowButtonLeft] = useState(false);
    const [showButtonRight, setShowButtonRight] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useTheme();
    const softwareName = theme?.config?.software_name || 'AIOS';
    const appMode = useAppMode();

    const executeAction = (action) => {
        handleClose();
        if (action)
            action();
    }

    const canShare = (shareableEntity !== null) && (shareableEndpoint !== null);

    const canEdit = shareableEntity ? shareableEntity?.can_edit : true;

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const hasActions = (hasRights(actionRights, "or") && (actions.length > 0 || onRemove || canShare));

    const StickHeaderTopDistance = (['plain', 'vertical'].includes(appMode?.service?.layout || appMode.layout)) ? 64 : 108;

    const BreadCrumbComponent = (breadcrumbs?.length > 0) && (
        <Breadcrumbs
            separator={(
                <NavigateNextIcon fontSize="small"/>
            )}
            color="text.secondary"
            aria-label="breadcrumb"
        >
            <Link
                underline="hover"
                variant="body1"
                color="inherit"
                to={'/groon/home'}
                component={RouterLink}
            >
                {softwareName}
            </Link>
            {breadcrumbs.map((breadcrumb, index) => (
                <Link
                    key={index}
                    underline="hover"
                    variant="body1"
                    color="inherit"
                    to={breadcrumb?.path || '#'}
                    component={RouterLink}
                >
                    {breadcrumb.title}
                </Link>
            ))}
        </Breadcrumbs>
    );

    const HeaderComponent = (
        <Stack
            alignItems={{
                xs: 'flex-start',
                md: 'center'
            }}
            direction={{
                xs: 'column',
                md: 'row'
            }}
            justifyContent="space-between"
            spacing={4}
        >
            <Stack
                direction="column"
                spacing={0}
                alignItems='flex-start'
            >
                {BreadCrumbComponent}
                {(title || subtitle || avatar) && (
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={2}
                    >
                        {avatar}
                        <Stack spacing={1}>
                            <Typography variant="h5" color="text.primary">
                                {title}
                            </Typography>
                            {typeof subtitle === 'string' ? (
                                <Typography variant="subtitle1" color="text.secondary">
                                    {subtitle}
                                </Typography>
                            ) : subtitle}
                        </Stack>
                    </Stack>
                )}
            </Stack>
            <Stack
                alignItems="center"
                direction="row"
                spacing={2}
            >
                {isEditing ? (
                    <>
                        <Button
                            color="inherit"
                            component={RouterLink}
                            onClick={onCancel}
                            variant="outlined"
                        >
                            {t("common.done")}
                        </Button>
                        <SaveButton
                            // Editing 2.0
                            formik={formik}
                            autoSave={autoSave}

                            loading={isSubmitting}
                            onClick={onSave}
                        />
                    </>
                ) : (
                    <>
                        {(hasRights(editRight) && (onEdit !== null) && canEdit) && (
                            <Button
                                color="inherit"
                                component={RouterLink}
                                variant="outlined"
                                startIcon={(
                                    <SvgIcon fontSize="small">
                                        <Edit02Icon/>
                                    </SvgIcon>
                                )}
                                onClick={onEdit}
                            >
                                {t("common.edit")}
                            </Button>
                        )}
                        {actionButton}
                        {hasActions && (
                            <Button
                                endIcon={(
                                    <SvgIcon fontSize="small">
                                        <ChevronDownIcon/>
                                    </SvgIcon>
                                )}
                                onClick={handleClick}
                                variant="contained"
                            >
                                {t("common.actions")}
                            </Button>
                        )}
                        <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            {actions.map((action, index) => action?.kind === 'divider' ? (
                                <Divider key={'divider-' + index}/>
                            ) : (
                                <Tooltip title={action?.tooltip} placement='left' key={'action-' + index}>
                                    <MenuItem
                                        onClick={() => executeAction(action?.action)}
                                        disabled={action?.disabled || false}
                                    >
                                        {action?.label || t('common.unknown')}
                                    </MenuItem>
                                </Tooltip>
                            ))}
                            {(actions.length > 0 && ((onRemove !== null) || canShare)) && <Divider/>}
                            {canShare && (
                                <ShareButton
                                    entity={shareableEntity}
                                    endpoint={shareableEndpoint}
                                    onOpen={handleClose}
                                />
                            )}
                            {canShare ? (
                                <RemoveSharableObject
                                    entity={shareableEntity}
                                    endpoint={shareableEndpoint}

                                    onRemove={onRemove}
                                    removeLabel={removeLabel}
                                    removeTooltip={removeTooltip}
                                    removeRight={removeRight}
                                />
                            ) : (hasRights(removeRight) && onRemove) && (
                                <Tooltip title={t(removeTooltip)} placement='left'>
                                    <MenuItem onClick={onRemove}>
                                        <Typography color="error">
                                            {t(removeLabel || 'common.remove_object_plain')}
                                        </Typography>
                                    </MenuItem>
                                </Tooltip>
                            )}
                        </Menu>
                    </>
                )}
            </Stack>
        </Stack>
    )

    useEffect(() => {
        setActive(false);
        setShowBreadcrumbs(false);
        setShowTitle(false);
        setTimeout(() => {
            setShowBreadcrumbs(breadcrumbs?.length > 0);
        }, 200);
        setTimeout(() => {
            setShowTitle(true);
        }, 400);
        setTimeout(() => {
            setActive(true);
        }, 600);
    }, []);

    useEffect(() => {
        if (isFlying) {
            setShowButtonLeft(false);
            setShowButtonRight(false);
            if (!isLoading) {
                setTimeout(() => {
                    setShowButtonLeft(true);
                }, 250);
                setTimeout(() => {
                    setShowButtonRight(true);
                }, 500);
            }
        } else {
            setShowButtonLeft(true);
            setShowButtonRight(true);
        }
    }, [isLoading, isFlying, isEditing]);

    return (
        <Page
            title={title || t('common.page')}
            rights={viewRights}
            pt={appMode.layout === 'vertical' ? pt : ((navBarMode === 'flying') ? 4 : (['flying-topbar', 'topbar'].includes(navBarMode)) ? 8 : pt)}
            pb={pb}
            fitScreenHeight={fitScreenHeight}
        >
            {isLoading ? (
                <div style={{
                    height: 'calc(100vh - 100px)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <DelayedCircularProgress/>
                </div>
            ) : (
                <>
                    {isFlying ? (
                        <>
                            <Fade in={showBreadcrumbs} timeout={APP_SETTING.transitionDuration || 500}>
                                <Box
                                    sx={{
                                        position: 'fixed',
                                        borderRadius: '30px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        top: '14px',
                                        p: 2,
                                        backdropFilter: 'blur(6px)',
                                        zIndex: (theme) => theme.zIndex.appBar,
                                    }}
                                >
                                    <Stack direction="row" sx={{width: '100%'}} justifyContent="center">
                                        {BreadCrumbComponent}
                                    </Stack>
                                </Box>
                            </Fade>
                            <Container maxWidth="xl" sx={{pt: 15}}>
                                <Fade in={showTitle} timeout={APP_SETTING.transitionDuration || 500}>
                                    <Stack
                                        alignItems="center"
                                        direction="row"
                                        spacing={2}
                                    >
                                        {avatar}
                                        <Stack spacing={0}>
                                            {title && (
                                                <Typography variant="h3" color="text.primary">
                                                    {title}
                                                </Typography>
                                            )}
                                            {subtitle}
                                        </Stack>
                                    </Stack>
                                </Fade>
                            </Container>
                            {isEditing ? (
                                <>
                                    <Fade in={showButtonLeft} timeout={APP_SETTING?.transitionDuration || 500}>
                                        <Box>
                                            <Tooltip title={t("common.done")} placement='right'>
                                                <Button
                                                    color="primary"
                                                    variant="outlined"
                                                    onClick={onCancel}
                                                    aria-label="cancel"
                                                    startIcon={(
                                                        <SvgIcon fontSize="small">
                                                            <OnIcon iconName="XClose"/>
                                                        </SvgIcon>
                                                    )}
                                                    sx={{
                                                        position: 'fixed',
                                                        top: 20,
                                                        left: 20
                                                    }}
                                                >
                                                    {t('common.done')}
                                                </Button>
                                            </Tooltip>
                                        </Box>
                                    </Fade>

                                    <Fade in={showButtonRight} timeout={APP_SETTING?.transitionDuration || 500}>
                                        <Box>
                                            <Tooltip title={formik ? null : t("common.save")} placement='left'>
                                                <SaveButton
                                                    // Editing 1.0
                                                    color="primary"
                                                    onClick={onSave}
                                                    aria-label="save"
                                                    sx={{
                                                        position: 'fixed',
                                                        top: 20,
                                                        right: 20
                                                    }}
                                                    loading={isSubmitting}

                                                    // Editing 2.0
                                                    formik={formik}
                                                    autoSave={autoSave}
                                                />
                                            </Tooltip>
                                        </Box>
                                    </Fade>
                                </>
                            ) : (
                                <>
                                    {(hasRights(editRight) && (onEdit !== null) && canEdit) && (
                                        <>
                                            <Fade in={showButtonLeft} timeout={APP_SETTING?.transitionDuration || 500}>
                                                <Box>
                                                    <Tooltip title={t("common.edit")} placement='left'>

                                                        <Button
                                                            // size="small"
                                                            variant="outlined"
                                                            color="primary"
                                                            onClick={onEdit}
                                                            aria-label="edit"
                                                            startIcon={(
                                                                <SvgIcon fontSize="small">
                                                                    <Edit02Icon/>
                                                                </SvgIcon>
                                                            )}
                                                            sx={{
                                                                position: 'fixed',
                                                                top: 20,
                                                                left: 20
                                                            }}
                                                        >
                                                            {t('common.edit')}
                                                        </Button>
                                                    </Tooltip>
                                                </Box>
                                            </Fade>
                                        </>
                                    )}
                                    <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{position: 'fixed', top: 20, right: 20}}>
                                        {actionButton && (
                                            <Fade in={showButtonRight} timeout={APP_SETTING?.transitionDuration || 500}>
                                                <Box>
                                                    {actionButton}
                                                </Box>
                                            </Fade>
                                        )}
                                        {hasActions && (
                                            <Fade in={showButtonRight} timeout={APP_SETTING?.transitionDuration || 500}>
                                                <Button
                                                    // size="small"
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={handleClick}
                                                    aria-label="actions"
                                                    startIcon={(
                                                        <SvgIcon fontSize="small">
                                                            <ChevronDownIcon/>
                                                        </SvgIcon>
                                                    )}
                                                >
                                                    {t('common.actions')}
                                                </Button>
                                            </Fade>
                                        )}
                                        <Menu
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                        >
                                            {actions.map((action, index) => action?.kind === 'divider' ? (
                                                <Divider key={'divider-' + index}/>
                                            ) : (
                                                <Tooltip title={action?.tooltip} placement='left'
                                                         key={'action-' + index}>
                                                    <MenuItem
                                                        onClick={() => executeAction(action?.action)}
                                                        disabled={action?.disabled || false}
                                                    >
                                                        {action?.label || t('common.unknown')}
                                                    </MenuItem>
                                                </Tooltip>
                                            ))}
                                            {(actions.length > 0 && ((onRemove !== null) || canShare)) && <Divider/>}
                                            {canShare && (
                                                <ShareButton
                                                    entity={shareableEntity}
                                                    endpoint={shareableEndpoint}
                                                    onOpen={handleClose}
                                                />
                                            )}
                                            {canShare ? (
                                                <RemoveSharableObject
                                                    entity={shareableEntity}
                                                    endpoint={shareableEndpoint}

                                                    onRemove={onRemove}
                                                    removeLabel={removeLabel}
                                                    removeTooltip={removeTooltip}
                                                    removeRight={removeRight}
                                                />
                                            ) : (hasRights(removeRight) && onRemove) && (
                                                <Tooltip title={t(removeTooltip)} placement='left'>
                                                    <MenuItem onClick={onRemove}>
                                                        <Typography color="error">
                                                            {t(removeLabel || 'common.remove_object_plain')}
                                                        </Typography>
                                                    </MenuItem>
                                                </Tooltip>
                                            )}
                                        </Menu>
                                    </Stack>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {headerSticky ? (
                                <BlurredBox
                                    sx={{
                                        position: 'sticky',
                                        top: StickHeaderTopDistance,
                                        pb: 2,
                                        zIndex: (theme) => theme.zIndex.appBar
                                    }}
                                >
                                    <Container
                                        id="sticky-header"
                                        maxWidth={headerFullWidth ? "xxl" : (headerMaxWidth ? headerMaxWidth : settings.stretch ? "xxl" : "xl")}
                                    >
                                        <Fade in={true} timeout={APP_SETTING.transitionDuration || 250}>
                                            {HeaderComponent}
                                        </Fade>
                                    </Container>
                                </BlurredBox>
                            ) : (
                                <Fade in={true} timeout={APP_SETTING.transitionDuration || 500}>
                                    <Container
                                        id="header"
                                        maxWidth={headerFullWidth ? "xxl" : (headerMaxWidth ? headerMaxWidth : settings.stretch ? "xxl" : "xl")}
                                    >
                                        {HeaderComponent}
                                    </Container>
                                </Fade>
                            )}
                        </>
                    )}
                    <Fade in={contentAvailable} timeout={APP_SETTING.transitionDuration || 500}>
                        <Container
                            maxWidth={contentFullWidth ? false : (contentMaxWidth ? contentMaxWidth : "xl")}
                            style={contentFullWidth ? {paddingLeft: 0, paddingRight: 0} : {}}
                            sx={{height: '100%'}}
                            id='detail-view-content-container'
                        >
                            <Box mt={isMobile ? 1 : isFlying ? 3 : 6} sx={{height: '100%'}}>
                                {children}
                            </Box>
                        </Container>
                    </Fade>
                </>
            )}
        </Page>
    )
}

DetailViewLayout.propTypes = {
    children: PropTypes.node.isRequired,
    breadcrumbs: PropTypes.array,
    viewRights: PropTypes.array,
    editRight: PropTypes.any,
    removeRight: PropTypes.any,

    // Layout
    headerFullWidth: PropTypes.bool,
    headerMaxWidth: PropTypes.string,
    contentFullWidth: PropTypes.bool,
    contentMaxWidth: PropTypes.string,
    headerSticky: PropTypes.bool,
    pb: PropTypes.number,
    pt: PropTypes.number,
    fitScreenHeight: PropTypes.bool,

    // Editing
    isEditing: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    onSave: PropTypes.func,
    onEdit: PropTypes.func,
    onCancel: PropTypes.func,

    // Editing 2.0
    formik: PropTypes.object,
    autoSave: PropTypes.bool,

    // Interface
    avatar: PropTypes.node,
    title: PropTypes.string,
    subtitle: PropTypes.any,
    isLoading: PropTypes.bool,

    // Actions
    actionRights: PropTypes.array,
    actions: PropTypes.array,
    actionButton: PropTypes.node,
    onRemove: PropTypes.func,
    removeLabel: PropTypes.string,
    removeTooltip: PropTypes.string,
    shareableEntity: PropTypes.object,
    shareableEndpoint: PropTypes.string,
}

export default DetailViewLayout;